<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur adj_sur2">
      <div class="label_element_cover">
        <label>Enter</label>
        <input type="text" placeholder="Search By Receipt No/Customer Ref No"
          title="Search By Receipt No/Customer Ref No" v-model="receiptNo" @keyup.enter="searchReceiptNo" />
      </div>
      <div class="label_element_cover">
        <label>Filter By Date</label>
        <flat-pickr class="date_for_pos_fixed" v-model="daterange" :config="configDate" placeholder="Date"
          name="birthdate" @on-close="dateChanged" @on-open="cleardatepicker"></flat-pickr>
      </div>


      <div class="bt_generate_label_cover">
        <!-- <div class="bt_generate_label_loader"><img  src="../../assets/ajax-loader.gif"></div> -->
        <a v-if="!dispatching" class="bt_generate_label" @click='dispatchMessenger()'>DISPATCH MESSENGER</a>
        <a v-if="dispatching" class="btn_dispatching">DISPATCHING {{ totalNumberOfDispatched }} of {{ languages.length }} JOBS. PLEASE WAIT...</a>
      </div>
    </div>
    <section class="print_hide">
      <div class="two_tabs">
        <a class="tab_buttons active">
          COURIER/MAILROOM/ENCODE
          <span class="label">24</span>
        </a>
      </div>
      <div class="jobs_table">
        <table>
          <tr>
            <th><input type="checkbox" @click='checkAll()' v-model='isCheckAll'></th>
            <th>DATE</th>
            <th>SERVICE TYPE</th>
            <th>CARRIER</th>
            <th>BOOKED BY</th>
            <th>ITEM TYPE</th>
            <th>PICKUP ADDRESS</th>
            <th>DELIVERY ADDRESS</th>
            <th>INSTRUCTIONS/GOODS DESCRIPTION</th>
            <th>STATUS</th>
          </tr>
          <tbody v-if="!loading">
            <tr v-for="(data, i) in courier" :key="i">
              <td><input type="checkbox" v-bind:value="data" v-model="languages" @change="updateCheckall()"></td>
              <!--   <td><input type="checkbox" v-bind:value="data" v-model="languages" @change="updateCheckall()" v-show="data.airway_bill == null" :disabled="data.airway_bill != null "></td> -->
              <td>
                <p class="text-center">
                  <span class="label">{{ data.booking_type }}</span>
                </p>
                <!-- <p class="text-center">{{ moment((data.created_at), ["DD-MM-YYYY", "MM-DD-YYYY"]).format("DD-MM-YYYY")}}</p> -->
                <P>{{ data.created_at | formatDate }}</P>
              </td>
              <td>
                <span class="label">{{ data.delivery_service }}</span>
                <!-- <p>{{data.delivery_service}}</p> -->
                <!-- <p>Cut of time: {{data.office_close_time}}</p> -->
              </td>
              <td>
                <p>{{ data.delivery_provider }}</p>

                AWB No:
                <div v-if="data.airway_bill" class="small_body">{{ data.airway_bill }}</div>
                <div v-else class="small_body">{{ data.booking_prefix }}{{ data.booking_id }}</div>

              </td>
              <td>
                <p>
                  <b>{{ data.booking_prefix }}{{ data.booking_id }}</b>
                </p>
                <p>{{ data.requester_name }}</p>
                <p>{{ moment((data.created_at), ["DD-MM-YYYY", "MM-DD-YYYY"]).format("DD-MM-YYYY") }}</p>
                <p>{{ data.branch_name }}</p>
                <p>Customer Reference: {{ data.reference_no }}</p>

              </td>
              <td>

                <div v-if="data.goods !== null">
                  {{ data.goods }}
                </div>
                <div v-else>
                  {{ data.type_of_good }}
                </div>
              </td>
              <td>
                <p>Collect from: {{ data.package_location }}</p>
                <p>{{ data.pickup_name }}</p>
                <p>{{ data.pickup_address_1 ? data.pickup_address_1 + ', ' : '' }}{{ data.pickup_address_2 ? data.pickup_address_2 + ', ' : ""}}{{ data.pickup_city ? data.pickup_city + ', ' : '' }}{{ data.pickup_state ? data.pickup_state + ', ' : '' }}{{ data.pickup_postal_code ? data.pickup_postal_code + ',':''}}{{ data.pickup_country }}</p>
              </td>
              <td>
                <p>Send to:</p>
                <p>{{ data.delivery_name }}</p>
                <p>{{ data.delivery_company }}</p>
                <p>{{ data.delivery_address_1 ? data.delivery_address_1 + ', ' : '' }}{{ data.delivery_address_2 ? data.delivery_address_2 + ', ' : '' }}{{ data.delivery_city ? data.delivery_city + ', ' : '' }}{{ data.delivery_state ? data.delivery_state + ', ' : '' }}{{ data.delivery_postal_code ? data.delivery_postal_code + ', ' : '' }}{{ data.delivery_country }}</p>
								<template v-if="data.secretary">
									<b>PA/EA:</b>
									<p style="padding-bottom: 0px;">{{ data.secretary.name }}</p>
                                    <span v-if="data.secretary.desk && extraAddressFieldEnabled">Desk# {{ data.secretary.desk }}, </span>
                                    <span v-if="data.secretary.floor && extraAddressFieldEnabled">Floor# {{ data.secretary.floor }}, </span>
									{{ data.secretary.address_2 ? data.secretary.address_2 + ', ' : '' }}
									{{ data.secretary.city ? data.secretary.city + ', ' : '' }}
									{{ data.secretary.state ? data.secretary.state + ', ' : '' }}
									{{ data.secretary.postal_code ? data.secretary.postal_code + ', ' : '' }}
									{{ data.secretary.country }}
								</template>
              </td>
              <td>
                <p>Instructions: <span><br />{{ data.pickup_instructions || 'NA' }}</span></p>
                <p>Goods Description: <span><br />{{ data.goods_description || 'NA' }}</span></p>
              </td>
              <td>
                <span class="label green" v-if="auth().user_type == 'user' || auth().user_type == 'hub_admin'">
                  {{ data.mail_type }}
                </span>
                <div class="icons_wrapper">
                  <div class="view_optons_wrapper" v-if="data.show_view_popup">
                    <a class="view_option_button" @click="searchConsignment(data)">View Consignment</a>
                    <a class="view_option_button" @click="viewSummary(data)">View Summary</a>
                    <a class="view_option_button" v-if="data.airway_bill && (data.file_name != null)"
                      :href="data.label_url" target="_blank">Print Label</a>
                  </div>
                  <a @click="data.show_view_popup = !data.show_view_popup">
                    <i class="material-icons blue" title="View">remove_red_eye</i>
                  </a>
                  <!-- <a  v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'">
                    <router-link title="Edit" :to="{ name: 'ServiceDetails', params: { id: data.id , type: 'edit' }}" v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'">
                      <i class="material-icons red" title="Edit">mode_edit</i>
                    </router-link>
                  </a>
                  <a v-else>
                    <router-link title="Edit" :to="{ name: 'QuickCourierBooking', params: { id: data.id  }}" v-if="data.status != 'Dispatched' && data.status != 'Slip Generated'">
                      <i class="material-icons red" title="Edit">mode_edit</i>
                    </router-link>
                      <i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i>
                  </a> -->

                  <a v-if="auth().user_type == 'super_admin' || auth().user_type == 'hub_admin'">
                    <a v-if="auth().user_type == 'super_admin'">
                      <!--    <router-link title="Edit" :to="{ name: 'ServiceDetails', params: { id: data.id , type: 'edit' }}" v-if="auth().user_type == 'super_admin'">
                        <i class="material-icons red" title="Edit">mode_edit</i>
                      </router-link> -->
                      <i class="material-icons grey" title="Could not be edited">mode_edit</i>
                    </a>
                    <a v-if="auth().user_type == 'hub_admin'">
                      <!-- <router-link title="Edit" :to="{ name: 'ServiceDetails', params: { id: data.id , type: 'edit' }}" v-if=" data.status != 'Dispatched' && data.status != 'Slip Generated'">
                        <i class="material-icons red" title="Edit" >mode_edit</i>
                      </router-link>
                        <i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i> -->
                      <i class="material-icons grey" title="Could not be edited">mode_edit</i>
                    </a>
                  </a>
                  <a v-else>
                    <i class="material-icons grey" title="Could not be edited">mode_edit</i>
                    <!--   <router-link title="Edit" :to="{ name: 'QuickCourierBooking', params: { id: data.id  }}" v-if="data.status != 'Dispatched' && data.status != 'Slip Generated'">
                      <i class="material-icons red" title="Edit">mode_edit</i>
                    </router-link>
                      <i class="material-icons grey" title="Could not be edited" v-else>mode_edit</i> -->
                  </a>

                  <a v-if="data.booking_type == 'Courier' || data.booking_type == 'Messenger'">
                    <router-link title="Update" :to="{ name: 'ServiceUpdate', params: { id: data.id, type: 'update' } }"
                      v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'">
                      <i class="material-icons red" title="Update">update</i>
                    </router-link>
                    <i class="material-icons grey" title="Could not be updated" v-else>update</i>

                  </a>
                  <!-- <a @click="deleteBooking(data.id)" v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'">
                    <i class="material-icons red" title="Delete">delete</i>
                  </a>
                  <a v-else>
                    <i @click="deleteBooking(data.id)" v-if="data.status!='Dispatched' && data.status != 'Slip Generated'" class="material-icons red" title="Delete">delete</i>
                    <i class="material-icons grey" title="Could not be deleted" v-else>delete</i>
                  </a> -->

                  <a v-if="auth().user_type == 'super_admin' || auth().user_type == 'hub_admin'">
                    <!--  <a @click="deleteBooking(data.id)" v-if="auth().user_type == 'super_admin'">
                      <i class="material-icons red" title="Delete">delete</i>
                    </a> -->
                    <i class="material-icons grey" title="Could not be deleted">delete</i>
                    <!-- <a v-else> -->
                    <a>
                      <!--  <i @click="deleteBooking(data.id)" v-if="data.status!='Dispatched' && data.status != 'Slip Generated'" class="material-icons red" title="Delete">delete</i>
                    <i class="material-icons grey" title="Could not be deleted" v-else>delete</i> -->
                      <!-- <i class="material-icons grey" title="Could not be deleted">delete</i> -->
                    </a>
                  </a>
                  <a v-else>
                    <!-- <i @click="deleteBooking(data.id)" v-if="data.status!='Dispatched' && data.status != 'Slip Generated'" class="material-icons red" title="Delete">delete</i>
                    <i class="material-icons grey" title="Could not be deleted" v-else>delete</i> -->
                    <i class="material-icons grey" title="Could not be deleted">delete</i>
                  </a>

                  <a @click="showpopCommentDiary = true">
                    <i class="material-icons orange" @click="addcomment(data)"
                      title="Type your Comment here">comment</i>
                  </a>
                </div>
                <a class="bt bt_black">{{ data.status }}</a>
                <span v-if="data.status == 'Error'"> {{ data.status_comment }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="initial_loading === 1 && courier.length == 0">No data found</p>
      </div>
      <loader v-if="loading" />

    </section>


    <div class="black-overlay" v-if="popcommentdiary_id">
      <div class="box-modal smallPops">
        <div class="body">
          <popCommentDiary @closeModal="popcommentdiary_id = ''" :id="popcommentdiary_id" v-if="popcommentdiary_id" />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
      <div class="box-modal printPops">
        <div class="body">
          <ViewConsignment @closeModal="closeConsignment" :booking_id="viewconsignmentpopup_booking_id"
            v-if="viewconsignmentpopup_booking_id" />
        </div>
      </div>
    </div>

    <div class="black-overlay" v-show="showpopSelectDestination">
      <div class="box-modal smallPops">
        <div class="body">
          <popErrorBookingidList :errors="showpopSelectDestination" v-if="showpopSelectDestination"
            @closeModal="closeError" />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import popUpdateStatus from "./popups/popUpdateStatus.vue";
import popCommentDiary from "./popups/popCommentDiary.vue";
import ViewConsignment from "./ViewConsignment.vue";
import moment from "moment";

import popErrorBookingidList from "./popups/popErrorBookingidList.vue";

export default {
  name: "ViewPending",
  components: {
    flatPickr,
    popUpdateStatus,
    popCommentDiary,
    ViewConsignment,
    popErrorBookingidList
  },
  filters: {
		formatDate(value) {
			if (!value) {
				return;
			}

			return moment(String(value)).format('DD-MM-YYYY')
		},
	},
  data() {
    return {
      showCheckbox: "",
      receiptNo: "",
      daterange: moment(new Date()).format('YYYY-MM-DD'),
      typeOfJob: "",
      enableTime: true,
      isCheckAll: false,
      languages: [],

      selectedlang: {
        courierID: [
          {
            id: ""
          }],

      },
      configDate: {
        mode: "range",
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      viewBoxShow: false,
      showpopUpdateStatus: false,
      showpopCommentDiary: false,
      showViewConsignment: false,
      courier: [],
      provider: "",
      pages: 0,
      current_page: 0,
      post_data: {
        value: "",
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
      },
      viewconsignmentpopup_booking_id: "",
      popupdatestatus_booking_id: "",
      popcommentdiary_id: "",
      showpopSelectDestination: "",
      loading: true,
      initial_loading: 0,
      dispatching: false,
      totalNumberOfDispatched: 0,
    };
  },

  created() {
    this.$emit("breadCrumbData", ["Dispatch Messenger"]);
    this.fetchCouriers();


    /*if(this.languages.length == this.courier.length){
         this.checkAll();
    }*/
    /*if(this.isCheckAll == true){
      this.updateCheckall();
      this.languages = [];
    }*/

    if (localStorage.viewPending) {
      let data = JSON.parse(localStorage.viewPending);
      if (data.jobType) {
        this.typeOfJob = data.jobType;
        this.post_data.jobType = data.jobType;
      }
      localStorage.removeItem("viewPending");
    }
  },

  /*mounted(){
    if(this.isCheckAll == true){
      this.languages = true;
    }
  },*/

  methods: {
    checkAll() {
      this.isCheckAll = !this.isCheckAll;

      this.languages = [];

      if (this.isCheckAll) {
        for (var key in this.courier) {
          // if(this.courier[key].airway_bill ==  null){
          this.languages.push(this.courier[key]);
          // }
        }
      }
    },
    updateCheckall() {
      if (this.languages.length == this.courier.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    async dispatchMessenger() {

        this.totalNumberOfDispatched = 0;
        this.dispatching = true;
        this.selectedlang.courierID = [];

        let i = 0;
        for (let key in this.languages) {

            this.totalNumberOfDispatched += 1;
            i += 1;

            try {
                await this.axios.post("/api/dispatchMessenger/dispatchMessengercreate", {
                    courierID: [{
                        id: this.languages[key].id
                    }],
                });
                
                const index = this.courier.indexOf(this.languages[key]);
                    
                this.toast.success(`Job with AWB ${this.languages[key].airway_bill} has been dispatched successfully.`);
                this.courier.splice(index, 1);
            }
            catch (error) {
                //
            }

            if (i == this.languages.length) {
                this.totalNumberOfDispatched = 0;
                this.dispatching = false;
                this.languages = [];
                this.isCheckAll = false;
            }
        }
    },
    closeError() {
      this.showpopSelectDestination = '';
      this.$router.go(this.$router.currentRoute);
    },

    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
      this.fetchCouriers();
    },
    cleardatepicker() {

      this.daterange = "";
    },
    searchReceiptNo() {
      this.post_data.value = this.receiptNo;
      this.fetchCouriers();
    },

    filterStatus() {
      this.post_data.statusfilter = this.status;
      this.fetchCouriers();
    },
    filterCourierType() {
      this.post_data.jobType = this.typeOfJob;
      this.fetchCouriers();
    },
    fetchCouriers() {

      this.loading = false;
      if (this.post_data.from != "" || this.post_data.to != "" || this.post_data.value != "") {

        this.loading = true;
        this.axios
          .post("/api/courier/listingAndFilterForDispatchMessenger", this.post_data)
          .then(response => {
            this.courier = response.data.couriers.list.map(element => {
              element.show_view_popup = false;
              element.label_url = element.airway_bill && element.file_name ? process.env.VUE_APP_API_URL + "/labels/" + element.file_name : "";

              return element;
            });
            this.initial_loading = 1;
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    closeConsignment() {
      this.viewconsignmentpopup_booking_id = '';
      this.courier = this.courier.map(element => {
        element.show_view_popup = false;
        return element;
      });
    },
    viewSummary(data) {
      localStorage.view_summary = JSON.stringify({
        booking_id: data.booking_id
      });
      this.$router.push("/pages/ViewProcessed");
    },
    searchConsignment(booking) {
      this.viewconsignmentpopup_booking_id = booking.booking_id;
    },
    updatestatus(booking) {
      this.popupdatestatus_booking_id = booking.booking_id;
    },
    /*statusUpdated(){
      this.popupdatestatus_booking_id = '';
      this.fetchCouriers(this.current_page);
    },*/
    addcomment(id) {
      this.popcommentdiary_id = id.id;
    },
    // deleteBooking(id) {
    //   if (confirm("Are you sure you want to delete this booking?")) {
    //     this.axios.delete("api/courier/" + id)
    //       .then(response => {
    //         this.fetchCouriers();
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       })
    //   }
    // }
  }
};
</script>
<style lang="scss">
$theme-color: #06A5ED;

.jobs_table {
  border-top: 5px solid $theme-color;

  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid #ccc;
    font-size: 14px;

    tr {
      th {
        background: #004a7c;
        padding: 20px 10px;
        color: #fff;
        font-weight: 700;
        border: 1px solid #222;
        border-left: 0;
        border-top: 0;
        text-align: left;
      }

      td {
        background: #eee;
        padding: 20px 10px;
        color: #333;
        font-weight: 400;
        border: 1px solid #ccc;
        border-left: 0;
        border-top: 0;
        text-align: left;
        vertical-align: top;

        p {
          padding: 5px 0;

          b {
            font-weight: 700;
          }
        }

        span.label {
          display: inline-block;
          font-weight: 700;
          background: #063657;
          color: #fff;
          border-radius: 4px;
          padding: 5px;

          &.green {
            background: #46aa42;
          }
        }

        .icons_wrapper {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          position: relative;

          a {
            display: inline-block;
            width: 24px;
            height: 24px;

            .material-icons {
              font-size: 18px;
              position: relative;
              top: 0;
              margin: 0;
              font-weight: normal;

              &.blue {
                color: rgb(45, 91, 241);
              }

              &.red {
                color: rgb(241, 74, 45);
              }

              &.green {
                color: #46aa42;
              }

              &.orange {
                color: orange;
              }
            }
          }
        }

        .bt {
          width: 120px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 4px;
          display: block;
          margin: 5px 0;
        }

        .bt_black {
          background: #063657;
          color: #fff;
        }

        .bt_gold {
          background: $theme-color;
          color: #000;
        }
      }
    }
  }
}

.bt_generate_label_cover {
  width: 120px;
  height: 38px;
  display: block;
  margin: 0 0 0 20px;
  position: relative;
}

.bt_generate_label_loader {
  width: 120px;
  height: 38px;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  cursor: wait;

  img {
    display: block;
    width: 38px;
    height: 38px;
    top: 0;
    left: 40px;
    position: absolute;
  }
}

.bt_generate_label {
  width: 175px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  display: block;
  background: $theme-color;
  color: #000;
}

.priority-chip {
  background-color: #F44336;
  color: white;
  padding: 5%;
  border-radius: 3px;
  font-size: 12px;
}

.priority-chip1 {
  background-color: #F70202;
  color: white;
  padding: 5%;
  border-radius: 3px;
  font-size: 12px;
}

.two_tabs {
  padding: 40px 10px 0 10px;
  display: flex;
  justify-content: space-around;

  .tab_buttons {
    display: block;
    width: 40%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #ececec;
    color: #333;
    border: 1px solid #ddd;

    span.label {
      display: none;
      background: $theme-color;
      color: #000;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }

  .tab_buttons.active {
    background: #004a7c;
    color: #fff;
  }
}

.view_optons_wrapper {
  width: 240px;
  height: auto;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -250px;
  top: 10px;
  padding: 10px;

  a.view_option_button {
    width: 100% !important;
    height: 40px !important;
    background: $theme-color;
    color: #000;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    line-height: 40px;
  }

  a.view_option_button:nth-child(2) {
    background: #aaa;
    color: #000;

    margin-bottom: 10px;
  }

  a.view_option_button:last-child {
    background: #aaa;
    color: #000;

    margin-bottom: 0;
  }
}
</style>
<style scoped>
.jobs_table table tr td:nth-child(6) p:nth-child(2) {
  color: red;
}

.jobs_table table tr td:nth-child(7) p:nth-child(2) {
  color: red;
}

.btn_dispatching {
    width: 310px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    display: block;
    background: #cccc;
    color: #000;
}
</style>