var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pop_container"},[_c('div',{staticClass:"title_area"},[_vm._v("UPDATE STATUS "),_c('a',{staticClass:"close",on:{"click":_vm.closeModal}},[_vm._v("X")])]),_c('div',{staticClass:"body_area"},[_c('div',{staticClass:"form_section_generate_slip"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('table',[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('ValidationProvider',{attrs:{"name":"status","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.statuscourier.status),expression:"statuscourier.status"}],class:errors[0] ? 'error-border':'',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.statuscourier, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select status")]),_c('option',{attrs:{"value":"Delivered"}},[_vm._v("Delivered")]),_c('option',{attrs:{"value":"Scan Run"}},[_vm._v("Scan Run")]),_c('option',{attrs:{"value":"PickedUp"}},[_vm._v("PickedUp")]),_c('option',{attrs:{"value":"Received"}},[_vm._v("Received")]),_c('option',{attrs:{"value":"Unable to Find Location"}},[_vm._v("Unable to Find Location")]),_c('option',{attrs:{"value":"Unable to Contact Recipient"}},[_vm._v("Unable to Contact Recipient")]),_c('option',{attrs:{"value":"No Such Person at Address"}},[_vm._v("No Such Person at Address")]),_c('option',{attrs:{"value":"Refused Delivery"}},[_vm._v("Refused Delivery")]),_c('option',{attrs:{"value":"Returned to Mail Room"}},[_vm._v("Returned to Mail Room")]),_c('option',{attrs:{"value":"Returned to Print Room"}},[_vm._v("Returned to Print Room")])])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                  Name\n                  "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.statuscourier.sender_or_receiver),expression:"statuscourier.sender_or_receiver"}],class:errors[0] ? 'error-border':'',attrs:{"type":"text","placeholder":"Name Character Limit 30","maxlength":_vm.max},domProps:{"value":(_vm.statuscourier.sender_or_receiver)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.statuscourier, "sender_or_receiver", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                  Date\n                  "),_c('span',[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"date","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:errors[0] ? 'border-danger':''},[_c('flat-pickr',{attrs:{"config":_vm.configDate,"placeholder":"Select Date","name":"birthdate"},model:{value:(_vm.statuscourier.date),callback:function ($$v) {_vm.$set(_vm.statuscourier, "date", $$v)},expression:"statuscourier.date"}})],1)]}}],null,true)})],1)]),_c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                  Time\n                  "),_c('span',[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"time","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{class:errors[0] ? 'error-border':'',attrs:{"config":_vm.configTime,"placeholder":"Select Time","name":"birthtime"},model:{value:(_vm.statuscourier.time),callback:function ($$v) {_vm.$set(_vm.statuscourier, "time", $$v)},expression:"statuscourier.time"}})]}}],null,true)})],1)])]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('ValidationProvider',{attrs:{"name":"comment","rules":"required|max:100","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                  Comment\n                  "),_c('span',[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.statuscourier.note),expression:"statuscourier.note"}],class:errors[0] ? 'error-border':'',attrs:{"placeholder":"Comment here Character Limit 100"},domProps:{"value":(_vm.statuscourier.note)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.statuscourier, "note", $event.target.value)}}})])]}}],null,true)})],1)])])]}}])}),_c('div',{staticClass:"pop_buttons_container"},[_c('a',{staticClass:"tr_cancel",on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('a',{staticClass:"tr_save",on:{"click":_vm.submit}},[_vm._v("Update")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }